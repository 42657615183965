<template>
    <div style="margin-top: 20px;">
        <a-table ref="table" size="middle" bordered rowKey="id" :columns="columns" :dataSource="dataSource"
            :pagination="ipagination" :loading="loading" @change="handleTableChange">
        </a-table>
    </div>
</template>

<script>
import { enterprise } from '@/api/other/other'
export default {
    name: "GuaranteeRelationship",
    data() {
        return {
            loading: true,
            dataSource: [],
            // 表头
            columns: [
                {
                    title: '序号',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '股东名称',
                    align: "center",
                    dataIndex: 'SHAREHOLDER_NAME'
                },
                {
                    title: '股份类型',
                    align: "center",
                    dataIndex: 'SHAREHOLDER_TYPE'
                },
                {
                    title: '持股比例',
                    align: "center",
                    dataIndex: 'RATIO'
                },
            ],
            ipagination: {
                current: 1,
                pageSize: 10,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                    return range[0] + "-" + range[1] + " 共" + total + "条"
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0
            },
        }
    },
    methods: {
        handleTableChange(val) {
            this.ipagination.current = val.current
            this.ipagination.pageSize = val.pageSize
            this.getInfo()
        },
        getInfo() {
            this.loading = true
            enterprise({ ENTERPRISE_ID: this.$route.query.ENTERPRISE_ID, TYPE_ONE: '股东信息', showCount: this.ipagination.pageSize, currentPage: this.ipagination.current }).then(res => {
                if (res.code == 200) {
                    this.dataSource = res.data.info
                    this.ipagination.total = res.data.page.totalResult
                    this.loading = false
                }
            })
        }

    },
    mounted() {

        this.getInfo()
    }
}
</script>

<style></style>