<template>
    <div class="main-box">
        <div class="corporate-portrait">企业画像</div>
        <div class="detail-show-content">
            <div class="enterprise-box">
                <div style="width: 40%;">
                    <div class="rate">
                    <img src="../assets/images/enterprise01.png" alt="" srcset="">
                    <div class="rate-D">{{ ENTERPRISE_Name }}</div>
                </div>
                </div>
               <div style="display: flex;width: 60%;justify-content: space-around;">
                <div class="rate">
                    <img src="../assets/images/enterprise02.png" alt="" srcset="">
                    <div class="rate-C">信贷额度:<div style="color: red;">{{ comprehensiveQuota || 0}}</div> </div>
                </div>
                <div class="rate">
                    <img src="../assets/images/enterprise03.png" alt="" srcset="">
                    <div class="rate-A">综合评级: <div style="color: red;">{{ rateResult || 0 }}</div> </div>
                </div>
                <div class="rate">
                    <img src="../assets/images/enterprise04.png" alt="" srcset="">
                    <div class="rate-B">综合评分: <div style="color: red;">{{ score || 0 }}</div> </div>
                </div>
               </div>
            </div>
            <div style="display: flex;width: 100%;margin-bottom: 15px;">
                <div class="sutitle-item" v-for="(item, index) in titleData" :key="index"
                    :class="{ 'active': currentSort == index }" @click="togglePage(item.nameEn, index)">{{ item.nameCH }}
                </div>
            </div>
            <div class="detail-main">
                <component :is="comName"></component>
            </div>
        </div>
    </div>
</template>

<script>
import BusinessInformation from './components/Enterprise/BusinessInformation.vue';     //工商信息组件
import GuaranteeRelationship from './components/Enterprise/GuaranteeRelationship.vue'; //股东信息组件
import JudicialInformation from './components/Enterprise/JudicialInformation.vue';     //司法信息组件
import IntangibleAssets from './components/Enterprise/IntangibleAssets.vue';           //无形资产组件
// import ComprehensiveQuota from './components/Enterprise/ComprehensiveQuota.vue';           //综合额度力组件
export default {
    name: "EnterpriseNameDetails",
    components: {
        BusinessInformation,      //工商信息组件
        GuaranteeRelationship,     //担保关系组件
        JudicialInformation,         //司法信息组件
        IntangibleAssets,             //无形资产组件
        // ComprehensiveQuota             //综合额度力组件
    },
    data() {
        return {
            ENTERPRISE_Name: "",
            currentSort: 0,
            titleData: [
                { nameEn: 'BusinessInformation', nameCH: '工商信息' },
                { nameEn: 'GuaranteeRelationship', nameCH: '股东信息' },
                { nameEn: 'JudicialInformation', nameCH: '司法信息' },
                { nameEn: 'IntangibleAssets', nameCH: '无形资产' },
                // { nameEn: 'ComprehensiveQuota', nameCH: '综合额度' }
            ],
            comName: "BusinessInformation",
            rateResult: '',
            score: "",
            comprehensiveQuota: ""
        }
    },
    methods: {
        togglePage(value, index) {
            this.currentSort = index;
            this.comName = value;
        }
    },
    mounted() {
        this.ENTERPRISE_Name = this.$route.query.ENTERPRISE_Name
        this.comprehensiveQuota = this.$route.query.comprehensiveQuota
        this.rateResult = this.$route.query.rateResult
        this.score = this.$route.query.score
    }
}
</script>

<style lang="less" scoped>
.main-box {
    width: @main-width-base;
    margin: 20px auto;

    .corporate-portrait {
        border-radius: 18px;
        background-color: #fff;
        box-shadow: 0px 0px 15px #b7b7b7;
        font-size: 24px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        line-height: 50px;
    }

    .enterprise-box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 20px;
        background-color: #fff;

        div {
            div {
                font-size: 20px
            }
        }

        .rate {
            display: flex;
            color: #3879d7;

            img {
                width: 30px;
                height: 30px;
            }

            .rate-A, .rate-B, .rate-C, .rate-D {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 5px;
                // color: #3879d7;
            }
        }

        div {
            font-weight: bold;
            font-size: 16px;
        }
    }

    .detail-show-content {
        border-radius: 18px;
        background-color: #fff;
        padding: 20px;
        box-shadow: 0px 0px 15px #b7b7b7;
        margin-top: 15px;
        .sutitle-item {
            width: 116px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            background-color: #f6f9fc;
            cursor: pointer;
        }

        .active {
            color: #fff;
            font-weight: bold;
            background-color: #1890ff;
        }
    }
}</style>