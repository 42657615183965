<template>
    <div class="businessInformation-box">
        <div class="businessInformation-item-box">
            <div class="item" v-for="item, index in list" :key="index" @click="toggle(index, item)"
                :class="{ 'active': index == current }">{{ item }}</div>
        </div>
        <div>
            <a-table ref="table" size="middle" bordered rowKey="id" :columns="columns" :dataSource="dataSource"
                :pagination="ipagination" :loading="loading" @change="handleTableChange">
            </a-table>
        </div>
    </div>
</template>
  
<script>
import { enterprise } from '@/api/other/other'
export default {
    name: "BusinessInformation",
    data() {
        return {
            current: 0,
            ipagination: {
                current: 1,
                pageSize: 10,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                    return range[0] + "-" + range[1] + " 共" + total + "条"
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0
            },
            list: [
                '软件著作权', '专利', '商标', '作品著作权'
            ],
            dataSource: [],
            loading: false,
            columns: [],
            columns1: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '软件名称',
                    align: "center",
                    dataIndex: 'SOFTWARE_NAME'
                },
                {
                    title: '软件简称',
                    align: "center",
                    dataIndex: 'SHORT_NAME'
                },
                {
                    title: '著作权人',
                    align: "center",
                    dataIndex: 'ENT_NAME'
                },
                {
                    title: '分类号',
                    align: "center",
                    dataIndex: 'TYPE_NUM'
                },
                {
                    title: '版本号',
                    align: "center",
                    dataIndex: 'VERSION_NUM'
                },
                {
                    title: '登记号',
                    align: "center",
                    dataIndex: 'REG_NUM'
                },
                {
                    title: '公告日期',
                    align: "center",
                    dataIndex: 'ANN_DATE'
                },
                {
                    title: '公告类型',
                    align: "center",
                    dataIndex: 'ANN_TYPE'
                },
                {
                    title: '登记日期',
                    align: "center",
                    dataIndex: 'REG_DATE'
                }
            ],
            columns2: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    fixed: 'left',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '专利标题',
                    width: 200,
                    align: "center",
                    dataIndex: 'TITLE'
                },
                {
                    title: '专利申请号',
                    width: 200,
                    align: "center",
                    dataIndex: 'APPLICATION_NUM'
                },
                {
                    title: '申请/专利权人',
                    width: 250,
                    align: "center",
                    dataIndex: 'PROPOSER'
                },
                {
                    title: '申请日期',
                    width: 110,
                    align: "center",
                    dataIndex: 'APPLICATION_DATE'
                },
                {
                    title: '优先权',
                    width: 200,
                    align: "center",
                    dataIndex: 'PRIORITY'
                },
                {
                    title: '颁证日期',
                    width: 200,
                    align: "center",
                    dataIndex: 'CERTIFIED_DATE'
                },
                {
                    title: '代理人',
                    width: 200,
                    align: "center",
                    dataIndex: 'AGENT'
                },
                {
                    title: '地址',
                    width: 200,
                    align: "center",
                    dataIndex: 'ADDRESS'
                },
                {
                    title: '分案申请',
                    width: 200,
                    align: "center",
                    dataIndex: 'DIVISIONAL_APPLICATION'
                },
                {
                    title: '范畴分类',
                    width: 200,
                    align: "center",
                    dataIndex: 'CATEGORY_TYPE'
                },
                {
                    title: '分类号',
                    width: 200,
                    align: "center",
                    dataIndex: 'CLASS_NUM'
                },
                {
                    title: '发明/设计人',
                    width: 200,
                    align: "center",
                    dataIndex: 'INVENTOR'
                },
                {
                    title: '国际公布',
                    width: 200,
                    align: "center",
                    dataIndex: 'INTERNATIONAL_PUBLICATION'
                },
                {
                    title: '国际申请',
                    width: 200,
                    align: "center",
                    dataIndex: 'INTERNATIONAL_APPLICATION'
                },
                {
                    title: '公开/公告号',
                    width: 200,
                    align: "center",
                    dataIndex: 'PUBLIC_NUM'
                },
                {
                    title: '公开/公告日',
                    width: 200,
                    align: "center",
                    dataIndex: 'PUBLIC_DATE'
                },
                {
                    title: '国省代码',
                    width: 200,
                    align: "center",
                    dataIndex: 'PROVINCE_CODE'
                },
                {
                    title: '进入国家日期',
                    width: 200,
                    align: "center",
                    dataIndex: 'ENTRY_DATE'
                },
                {
                    title: '引证文献',
                    width: 200,
                    align: "center",
                    dataIndex: 'CITING_LITERATURE'
                },
                {
                    title: '主分类号',
                    width: 200,
                    align: "center",
                    dataIndex: 'MAIN_CLASSIFICATION'
                },
                {
                    title: '专利代理机构',
                    width: 220,
                    align: "center",
                    dataIndex: 'PATENT_AGENCY'
                },
                {
                    title: '主权项',
                    width: 200,
                    align: "center",
                    dataIndex: 'SOVEREIGNTY_ITEM'
                },
                {
                    title: '摘要',
                    align: "center",
                    dataIndex: 'SUMMARY_INFO'
                }
            ],
            columns3: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '商标名称(中文)',
                    width: 100,
                    align: "center",
                    dataIndex: 'NAME'
                },
                {
                    title: '商标类群详情',
                    width: 110,
                    align: "center",
                    dataIndex: 'GROUP_DETAILS'
                },
                {
                    title: '申请日期',
                    width: 120,
                    align: "center",
                    dataIndex: 'APPLICATION_DATE'
                },
                {
                    title: '注册日期',
                    width: 120,
                    align: "center",
                    dataIndex: 'REGISTRATION_DATE'
                },
                {
                    title: '申请/注册号',
                    width: 120,
                    align: "center",
                    dataIndex: 'REGISTRATION_NUM'
                },
                {
                    title: '申请人地址',
                    align: "center",
                    dataIndex: 'APPLICANT_ADDRESS'
                },
                {
                    title: '代理人',
                    align: "center",
                    dataIndex: 'AGENT'
                },
                {
                    title: '申请人',
                    align: "center",
                    dataIndex: 'APPLICANT'
                }
            ],
            columns4: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '作品名称',
                    align: "center",
                    dataIndex: 'P_NAME'
                },
                {
                    title: '公告日期',
                    align: "center",
                    dataIndex: 'ANN_DATE'
                },
                {
                    title: '创作完成日期',
                    align: "center",
                    dataIndex: 'COM_DATE'
                },
                {
                    title: '著作权人',
                    align: "center",
                    dataIndex: 'ENT_NAME'
                },
                {
                    title: '登记日期',
                    align: "center",
                    dataIndex: 'REG_DATE'
                }
            ],
            TYPE_TWO:"软件著作权"

        }
    },
    methods: {
        toggle(index, item) {
            this.ipagination.current = 1
            this.ipagination.pageSize = 10
            this.current = index
            this.TYPE_TWO = item
            if (index == 0) {
                this.columns = this.columns1
            } else if (index == 1) {
                this.columns = this.columns2
            } else if (index == 2) {
                this.columns = this.columns3
            } else if (index == 3) {
                this.columns = this.columns4
            }
            this.getInfo()
        },
        handleTableChange(val) {
            this.ipagination.current = val.current
            this.ipagination.pageSize = val.pageSize
            this.getInfo()
        },
        getInfo() {
            this.loading = true
            enterprise({ ENTERPRISE_ID: this.$route.query.ENTERPRISE_ID, TYPE_ONE: '科创能力', TYPE_TWO: this.TYPE_TWO,showCount:this.ipagination.pageSize,currentPage:this.ipagination.current }).then(res => {
                if (res.code == 200) {
                    console.log(res);
                    this.dataSource = res.data.info
                    this.ipagination.total = res.data.page.totalResult
                    this.loading = false
                }
            })
        }
    },
    mounted() {
        this.columns = this.columns1
        this.getInfo()
    }
}
</script>
  
<style lang="less" scoped>
.businessInformation-box {
    .businessInformation-item-box {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .item {
            background-color: #f6f9fc;
            border-radius: 4px;
            padding: 6px 10px;
            cursor: pointer;
            margin-left: 15px;
        }
        .item:nth-child(1){
            margin-left: 0px;
        }
        .active {
            background-color: #6D86E4 ;
            color: #fff;
        }
    }
}
</style>