<template>
    <div class="businessInformation-box">
        <div class="businessInformation-item-box">
            <div class="item" v-for="item, index in list" :key="index" @click="toggle(index, item)"
                :class="{ 'active': index == current }">{{ item }}</div>
        </div>
        <div>
            <a-table v-if="current == 4" ref="table" size="middle" bordered rowKey="id" :columns="columns"
                :dataSource="dataSource" :pagination="ipagination" :loading="loading" @change="handleTableChange"
                :scroll="{ x: 2600 }">
            </a-table>
            <a-table v-else ref="table" size="middle" bordered rowKey="id" :columns="columns" :dataSource="dataSource"
                :pagination="ipagination" :loading="loading" @change="handleTableChange">
            </a-table>
        </div>
    </div>
</template>
  
<script>
import { enterprise } from '@/api/other/other'
export default {
    name: "JudicialInformation",
    data() {
        return {
            current: 0,
            /* 分页参数 */
            ipagination: {
                current: 1,
                pageSize: 10,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                    return range[0] + "-" + range[1] + " 共" + total + "条"
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0
            },
            list: ["司法案件", "被执行人", "立案信息", "开庭公告", "失信信息", "限制消费", "终本案件", "法院公告", "股权冻结"],
            dataSource: [],
            loading: true,
            columns: [],
            // 司法案件
            columns1: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '案号',
                    align: "center",
                    dataIndex: 'CASE_NO'
                },
                {
                    title: '案由',
                    width: 100,
                    align: "center",
                    dataIndex: 'CASE_REASON'
                },
                {
                    title: '案件名称',
                    align: "center",
                    dataIndex: 'TITLE'
                },
                {
                    title: '案件类型',
                    width: 100,
                    align: "center",
                    dataIndex: 'CASE_TYPE ',
                },
                {
                    title: '案件身份',
                    width: 100,
                    align: "center",
                    dataIndex: 'dataType_dictText'
                },
              
                
                {
                    title: '案件金额(元)',
                    align: "center",
                    dataIndex: 'CASE_MONEY'
                },
                {
                    title: '法院',
                    align: "center",
                    dataIndex: 'COURT'
                },
                {
                    title: '发布日期',
                    align: "center",
                    dataIndex: 'SUBMIT_TIME'
                }
            ],
            // 被执行人
            columns2: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '案号',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASE_NO'
                },
                {
                    title: '被执行人',
                    width: 150,
                    align: "center",
                    dataIndex: 'PERSON_NAME'
                },
                {
                    title: '年龄',
                    width: 150,
                    align: "center",
                    dataIndex: 'AGE'
                },
                {
                    title: '关注次数',
                    width: 150,
                    align: "center",
                    dataIndex: 'FOCUS_NUM'
                },
                {
                    title: '执行标的(元)',
                    width: 150,
                    align: "center",
                    dataIndex: 'SUBJECT_MATTER'
                },
                {
                    title: '执行法院',
                    width: 150,
                    align: "center",
                    dataIndex: 'EXECUTIVE_COURT'
                },
                {
                    title: '立案日期',
                    width: 150,
                    align: "center",
                    dataIndex: 'FILING_TIME'
                }
            ],
            // 立案信息
            columns3: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '案号',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASE_NO'
                },
                {
                    title: '案由',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASE_REASON'
                },
                {
                    title: '当事人',
                    width: 150,
                    align: "center",
                    dataIndex: 'LITIGANT'
                },
                {
                    title: '法院',
                    width: 150,
                    align: "center",
                    dataIndex: 'COURT'
                },
                {
                    title: '案件类型',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASE_TYPE'
                }
            ],
            // 开庭公告
            columns4: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '案号',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASE_NO'
                },
                {
                    title: '案由',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASE_REASON'
                },
                {
                    title: '当事人',
                    width: 150,
                    align: "center",
                    dataIndex: 'LITIGANT'
                },
                {
                    title: '法院',
                    width: 150,
                    align: "center",
                    dataIndex: 'COURT'
                },
                {
                    title: '法庭',
                    width: 150,
                    align: "center",
                    dataIndex: 'COURTROOM'
                },
                {
                    title: '审判长/主审人',
                    width: 150,
                    align: "center",
                    dataIndex: 'JUDGE'
                },
                {
                    title: '开庭时间',
                    width: 150,
                    align: "center",
                    dataIndex: 'START_DATE'
                }
            ],
            // 失信信息
            columns5: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '案号',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASE_NO'
                },
                {
                    title: '法人、负责人姓名',
                    width: 150,
                    align: "center",
                    dataIndex: 'LEGA_PERSON'
                },
                {
                    title: '身份证号码/工商注册号',
                    width: 150,
                    align: "center",
                    dataIndex: 'ID_NUM'
                },
                {
                    title: '法院',
                    width: 150,
                    align: "center",
                    dataIndex: 'EXECUTIVE_COURT'
                },
                {
                    title: '省份地区',
                    width: 150,
                    align: "center",
                    dataIndex: 'PROVINCE'
                },
                {
                    title: '做出执行的依据单位',
                    width: 150,
                    align: "center",
                    dataIndex: 'UNIT_NAME'
                },
                {
                    title: '被执行人履行情况',
                    width: 150,
                    align: "center",
                    dataIndex: 'PERFORMANCE'
                },
                {
                    title: '已履行(元)',
                    width: 150,
                    align: "center",
                    dataIndex: 'HAVE_PERFORM'
                },
                {
                    title: '未履行(元)',
                    width: 150,
                    align: "center",
                    dataIndex: 'UN_PERFORM'
                },
                {
                    title: '发布时间',
                    width: 150,
                    align: "center",
                    dataIndex: 'RELEASE_TIME'
                },
                {
                    title: '立案时间',
                    width: 150,
                    align: "center",
                    dataIndex: 'FILING_TIME'
                }
            ],
            // 限制消费
            columns6: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '案号',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASE_CODE'
                },
                {
                    title: '企业信息',
                    width: 150,
                    align: "center",
                    dataIndex: 'QYINFO'
                },
                {
                    title: '限制消费者名称',
                    width: 150,
                    align: "center",
                    dataIndex: 'TERC_XNAME'
                },
                {
                    title: '立案时间',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASE_CREATE_TIME'
                },
                {
                    title: '发布日期',
                    width: 150,
                    align: "center",
                    dataIndex: 'TERC_PUBLISH_DATE'
                }
            ],
            // 终本案件
            columns7: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '案号',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASE_CODE'
                },
                {
                    title: '立案时间',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASECREATE_TIME'
                },
                {
                    title: '终本日期',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASE_FINAL_TIME'
                },
                {
                    title: '执行法院',
                    width: 150,
                    align: "center",
                    dataIndex: 'EXECCOURT_NAME'
                },
                {
                    title: '未履行金额',
                    width: 150,
                    align: "center",
                    dataIndex: 'NO_EXEC_MONEY'
                },
                {
                    title: '被执行人名称',
                    width: 150,
                    align: "center",
                    dataIndex: 'ZNAME'
                }
            ],
            // 法院公告
            columns8: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '案号',
                    width: 150,
                    align: "center",
                    dataIndex: 'CASE_NO'
                },
                {
                    title: '案由',
                    width: 150,
                    align: "center",
                    dataIndex: 'REASON'
                },
                {
                    title: '当事人',
                    width: 150,
                    align: "center",
                    dataIndex: 'PARTY'
                },

                {
                    title: '法院',
                    width: 150,
                    align: "center",
                    dataIndex: 'COURT_NAME'
                },
                {
                    title: '省份',
                    width: 150,
                    align: "center",
                    dataIndex: 'PROVINCE'
                },
                {
                    title: '刊登日期',
                    width: 150,
                    align: "center",
                    dataIndex: 'PUBLISH_DATE'
                }
            ],
            // 股权冻结
            columns9: [
                {
                    title: '序号',
                    dataIndex: '',
                    key: 'rowIndex',
                    width: 60,
                    align: "center",
                    customRender: ({ t, r, index }) => {
                        return `${index + 1}`;
                    }
                },
                {
                    title: '被执行人持有股权、其它投资权益的数额',
                    width: 150,
                    align: "center",
                    dataIndex: 'EQUITY_AMOUNT_OTHER'
                },
                {
                    title: '被执行人',
                    width: 150,
                    align: "center",
                    dataIndex: 'EXECUTED_PERSON'
                },
                {
                    title: '执行法院',
                    width: 150,
                    align: "center",
                    dataIndex: 'EXECUTIVE_COURT'
                },
                {
                    title: '执行事项',
                    width: 150,
                    align: "center",
                    dataIndex: 'IMPLEMENTATION_MATTERS'
                },
                {
                    title: '被执行人证照号码',
                    width: 150,
                    align: "center",
                    dataIndex: 'LICENSE_NUM'
                },
                {
                    title: '被执行人证照种类',
                    width: 150,
                    align: "center",
                    dataIndex: 'LICENSE_TYPE'
                },
                {
                    title: '冻结期限',
                    width: 150,
                    align: "center",
                    dataIndex: 'PERIOD'
                },
                {
                    title: '冻结公示日期',
                    width: 150,
                    align: "center",
                    dataIndex: 'PUBLICITY_AATE'
                }
            ],
            TYPE_TWO:'司法案件'
        }
    },
    methods: {
        toggle(index, item) {
            this.current = index
            this.TYPE_TWO = item
            if (index == 0) {
                this.columns = this.columns1
            } else if (index == 1) {
                this.columns = this.columns2
            } else if (index == 2) {
                this.columns = this.columns3
            } else if (index == 3) {
                this.columns = this.columns4
            } else if (index == 4) {
                this.columns = this.columns5
            } else if (index == 5) {
                this.columns = this.columns6
            } else if (index == 6) {
                this.columns = this.columns7
            } else if (index == 7) {
                this.columns = this.columns8
            } else if (index == 8) {
                this.columns = this.columns9
            } 
            this.getInfo()
        },
        handleTableChange(val) {
            this.ipagination.current = val.current
            this.ipagination.pageSize = val.pageSize
            this.getInfo()
        },
        getInfo(item) {
            this.loading = true
            enterprise({ ENTERPRISE_ID: this.$route.query.ENTERPRISE_ID, TYPE_ONE: '司法信息', TYPE_TWO: this.TYPE_TWO,showCount:this.ipagination.pageSize,currentPage:this.ipagination.current  }).then(res => {
                if (res.code == 200) {
                    this.dataSource = res.data.info
                    this.ipagination.total = res.data.page.totalResult
                    this.loading = false
                }
            })
        }
    },
    mounted() {
        this.columns = this.columns1
        this.getInfo('司法案件')
    }
}
</script>
  
<style lang="less" scoped>
.businessInformation-box {
    .businessInformation-item-box {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .item {
            background-color: #f6f9fc;
            border-radius: 4px;
            padding: 6px 10px;
            cursor: pointer;
            margin-left: 15px;
        }
        .item:nth-child(1){
            margin-left: 0px;
        }
        .active {
            background-color: #6D86E4 ;
            color: #fff;
        }
    }
}
</style>