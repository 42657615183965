<template>
  <div class="business-information">
    <a-descriptions title="" bordered>
      <a-descriptions-item label="企业名称">{{ information.ENTERPRISE_NAME }}</a-descriptions-item>
      <a-descriptions-item label="统一社会信用代码">{{ information.CREDIT_CODE }}</a-descriptions-item>
      <a-descriptions-item label="法定代表人">{{ information.LEGAL_PERSON_NAME }}</a-descriptions-item>
      <a-descriptions-item label="电话">{{ information.CONTACT_PHONE }}</a-descriptions-item>
      <!-- <a-descriptions-item label="等级状态">{{ information.ENTERPRISE_NAME }}</a-descriptions-item> -->
      <a-descriptions-item label="组织机构代码">{{ information.ENTERPRISE_NAME }}</a-descriptions-item>
      <a-descriptions-item label="成立日期">{{ information.ESTABLISHED_TIME }}</a-descriptions-item>
      <a-descriptions-item label="官网">{{ information.hostName }}</a-descriptions-item>
      <a-descriptions-item label="注册资本">{{ information.REGISTERED_CAPITAL }}</a-descriptions-item>
      <a-descriptions-item label="从业人数">{{ information.STAFF_SIZE }}</a-descriptions-item>
      <!-- <a-descriptions-item label="国际行业">{{ information.ENTERPRISE_NAME }}</a-descriptions-item> -->
      <!-- <a-descriptions-item label="分公司数量">{{ information.ENTERPRISE_NAME }}</a-descriptions-item> -->
      <!-- <a-descriptions-item label="工商注册号">{{ information.ENTERPRISE_NAME }}</a-descriptions-item> -->
      <!-- <a-descriptions-item label="所属地区">{{ information.ENTERPRISE_NAME }}</a-descriptions-item> -->
      <!-- <a-descriptions-item label="企业资质">{{ information.ENTERPRISE_NAME }}</a-descriptions-item> -->
      <!-- <a-descriptions-item label="企业规模">{{ information.ENTERPRISE_NAME }}</a-descriptions-item> -->
      <a-descriptions-item label="登记机关">{{ information.REGISTRATION_AUTHORITY }}</a-descriptions-item>
      <a-descriptions-item label="注册地址">{{ information.REGISTERED_ADDR }}</a-descriptions-item>
      <a-descriptions-item label="司法诉讼数">{{ information.legalProceedingsNums }}</a-descriptions-item>
      <!-- <a-descriptions-item label="所属产业链">{{ information.ENTERPRISE_NAME }}</a-descriptions-item> -->
      <a-descriptions-item label="企业类型">{{ information.ENTERPRISE_TYPE }}</a-descriptions-item>
      <!-- <a-descriptions-item label="融资阶段">{{ information.ENTERPRISE_NAME }}</a-descriptions-item> -->
      <a-descriptions-item label="专利数量">{{ information.patentNums }}</a-descriptions-item>
      <a-descriptions-item label="登记状态">{{ information.REGISTRATION_STATUS }}</a-descriptions-item>
      <a-descriptions-item label="主营产品" :span="3">{{ information.ENTERPRISE_NAME }}</a-descriptions-item>
      <a-descriptions-item label="经营范围" :span="3">{{ information.BUSINESS_SCOPE }}</a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
import { enterprise } from '@/api/other/other'
export default {
  name: "BusinessInformation",
  data() {
    return {
      information: {}
    }
  },
  mounted() {
    enterprise({ ENTERPRISE_ID: this.$route.query.ENTERPRISE_ID, TYPE_ONE: '工商信息' }).then(res => {
      if (res.code == 200) {
        this.information = res.data.info
      }
    })
  }
}
</script>

<style lang="less" scoped>
  ::v-deep .ant-descriptions-item-label{
    width: 180px;
  }
</style>